<template>
    <div class="publication">
        <div class="hero">
            <slot name="hero" />
        </div>

        <div class="main">
            <section v-if="$slots.head" class="head">
                <div class="head-content">
                    <slot name="head" />
                </div>
            </section>
            <div class="content">
                <MainWithAsidesTier hideAsidesMobile>
                    <template #main-left>
                        <slot name="main-left" />
                    </template>
                    <template #main-center>
                        <section v-if="pageLanguage === 'ru'" class="disclaimer top">
                            <Typography variant="body-display-micro-italic">{{
                                'Российская Федерация включила Фонд Карнеги за международный мир в список «нежелательных организаций». Если вы находитесь на территории России, пожалуйста, не размещайте публично ссылку на эту статью.'
                            }}</Typography>
                        </section>
                        <slot name="main-center">
                            <PublicationStreamBlocks
                                v-if="streamBlocks && streamBlocks.length"
                                :blocks="streamBlocks"
                                class="stream"
                            />
                        </slot>
                    </template>
                    <template #main-right>
                        <slot name="main-right" />
                    </template>
                </MainWithAsidesTier>
                <section v-if="$slots.chapters" class="chapters">
                    <slot name="chapters"> </slot>
                </section>
                <MainWithAsidesTier v-if="tags.length" hideAsidesMobile>
                    <template #main-center>
                        <TagCluster :items="tags" class="tags" />
                        <section class="disclaimer">
                            <Typography variant="body-display-micro-italic">{{
                                $t(
                                    'Carnegie does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.',
                                    1,
                                    { locale: pageLanguage },
                                )
                            }}</Typography>
                        </section>
                    </template>
                </MainWithAsidesTier>
                <MainWithAsidesTier v-else>
                    <template #main-center>
                        <section class="disclaimer">
                            <Typography variant="body-display-micro-italic">{{
                                $t(
                                    'Carnegie does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.',
                                    1,
                                    { locale: pageLanguage },
                                )
                            }}</Typography>
                        </section>
                    </template>
                </MainWithAsidesTier>

                <MainWithAsidesTier class="mobile-asides">
                    <template #main-left>
                        <slot name="main-left" />
                    </template>
                    <template #main-right>
                        <slot name="main-right" />
                    </template>
                </MainWithAsidesTier>
            </div>

            <section v-if="$slots.footer" class="footer">
                <slot name="footer"> </slot>
            </section>
        </div>
    </div>
</template>

<script setup>
const { pageLanguage } = usePageDataStore();
const props = defineProps({
    streamBlocks: {
        default: () => [],
    },
    programs: {
        type: Array,
        default: () => [],
    },
    projects: {
        type: Array,
        default: () => [],
    },
    tags: {
        type: Array,
        default: () => [],
    },
});
</script>

<style lang="scss" scoped>
.head {
    @include content-section;
    @include grid;
    @include z-index(arbitrary);
    position: relative;
    margin-top: 4.4rem;
    margin-inline-start: auto;
    margin-inline-end: auto;

    @include media-query(mobile) {
        padding-inline-start: 0;
        padding-inline-end: 0;
    }
}

.head-content {
    @include content-padding;
    grid-column: 4 / span 6;

    @include media-query(mobile) {
        order: 2;
        grid-column: 1 / -1;
    }
}
.content {
    padding-top: 3rem;
    padding-bottom: vertical-space(6);
}

.head-content {
    @include media-query(phone) {
        grid-column: 1 / -1;
    }
}

.stream {
    padding-bottom: vertical-space(3);
}

.card {
    &:not(:last-child) {
        border-bottom: 1px solid palette(divider);
    }
}
.mobile-asides {
    margin-top: vertical-space(3);
    @include media-query(tablet-mw) {
        display: none;
    }
}
.disclaimer {
    margin: 4.8rem 0;
    &.top {
        margin-top: 0;
    }
}
</style>
